<template>
  <div class="course">
    <div class="cont mt-40">
      <div class="width_1200">
        <div class="home_row">
          <div
            class="hover_shadow home_col"
            v-for="(item, index) in list"
            :key="index"
            @click="goToTeacherDetail(item.Id)"
          >
            <img v-lazy="item.HeadImg" alt="" srcset="" />
            <div class="title decimalPoint">{{ item.Name }}</div>
            <div class="intr decimalPoint-2">{{ item.ShortTitle }}</div>
          </div>
        </div>
        <div v-if="list.length <= 0">
          <no-data></no-data>
        </div>
      </div>
      <div class="width_1200">
        <el-pagination
          v-if="list.length > 0"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { baseQueryTeacherList } from "@/api/home";
import { queryArtist } from "@/api/fineArts";
import noData from "@/components/noData";
export default {
  props: ["type"],
  data () {
    return {
      list: [],
      total: 0,
      page: 1,
      pageSize: 15,
      route: {}
    };
  },
  components: {
    noData
  },
  created () {
  },
  mounted () {
    this.route = this.$route.query
    this.init()
    if (this.route.type == 1) {
      document.title = "资深讲师"
    } else {
      document.title = "专家团队"
    }
  },
  methods: {
    goToTeacherDetail (id) {
      this.$router.push({ path: '/teacher/teacherDetails', query: { id: id, type: this.route.type } });
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.init();
    },
    // 列表数据
    async init () {
      let parm = {}
      parm.pageIndex = this.page;
      parm.pageSize = this.pageSize;
      const res = this.route.type == 1 ?
        await baseQueryTeacherList(parm) :
        await queryArtist(parm);
      if (res.success == true) {
        this.list = res.response.data
        this.total = res.response.dataCount;
        if (this.route.type != 1) {
          this.list.forEach(element => {
            element.Name = element.ArtistName
            element.ShortTitle = element.BriefIntroduce
          });
          // console.log(this.list, '')
        }
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.home_row {
  display: flex;
  flex-wrap: wrap;
  .home_col {
    flex: 0 0 218px;
    width: 218px;
    height: 354px;
    margin-right: 27px;
    margin-bottom: 28px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    &:nth-child(5n) {
      margin-right: 0;
    }
  }
}
img {
  width: 100%;
  height: 218px;
}
.title {
  padding: 0 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  line-height: 1;
  margin-bottom: 14px;
  margin: 18px 0;
  text-align: center;
}
.intr {
  padding: 0 20px;
  line-height: 24px;
  text-align: center;
}
/deep/.el-pagination {
  text-align: right;
  .el-pager {
    li {
      background-color: #fff;
      color: #999999;
    }
  }
}
</style>